<template>
  <div class="body-1">
    <v-card>
      <v-card-title> Webhooks </v-card-title>

      <v-alert type="error" v-if="webhookError">{{ webhookError }}</v-alert>

      <v-data-table
        :headers="headers"
        :items="webhooks"
        :loading="isLoadingAccount"
        class="elevation-1"
      >
        <template v-slot:[`item.active`]="{ item }">
          {{ item.active ? "Yes" : "No" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            style="color: green"
            @click="toggle(item)"
            v-if="item.active"
            title="Toggle off"
          >
            mdi-toggle-switch
          </v-icon>
          <v-icon @click="toggle(item)" v-if="!item.active" title="Toggle on">
            mdi-toggle-switch-off
          </v-icon>
          <v-icon @click="deleteWH(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:[`no-data`]>
          <td>No data found</td>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Webhook
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">New Webhook</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newWebhook.friendlyName"
                      :rules="[rules.required, rules.length50]"
                      label="Friendly Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newWebhook.url"
                      :rules="[rules.required, rules.url, rules.length255]"
                      label="URL"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="newWebhook.configurationId"
                      :items="configurationList"
                      :item-text="configName"
                      item-value="configurationId"
                      single-line
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="add"
                :disabled="allowSave"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
    <br />
    <v-card>
      <v-card-title> Documentation </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <p>
              LiveCad supports adding webhooks to your application. These
              webhooks allow LiveCad to send updates to various resources or
              incidents updated within the system to any system of your choice.
            </p>
          </v-col>
          <v-col>
            <h3>Sample JSON</h3>
            <pre>
              <code class="language-json">
{
  "Data": {
    "AgencyId": "OH-99-MECCLICK",
    "IncidentId": "25FD130:2021-002135",
    "IncidentDate": "2021-05-18T19:46:24.097Z",
    "Address": "920 N HAMILTON RD #600",
    "AlternateAddress": null,
    "City": "GAHANNA",
    "State": "OH",
    "ZipCode": "43230",
    "County": "MECC",
    "Nature": "BREATHING PROBLEMS",
    "AlternateNature": null,
    "Zone": "133 F|MECCEMS1",
    "Latitude": 40.042067,
    "Longitude": -82.863996,
    "AgencyTag": "MECC",
    "AssignedResources": [
      {
        "Divider": false,
        "ResourceAlias": "M134",
        "Ordinal": 0,
        "AgencyId": null,
        "DepartmentId": "25FD130",
        "ResourceId": "M134",
        "ResourceType": "M",
        "ResourceStatus": "ER MCE"
      }
    ],
    "UpdateHash": null
  },
  "Type": "Incident:Updated",
  "Timestamp": 1621368552.8448043
}
              </code>
            </pre>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Prism from "prismjs";
import "prismjs/themes/prism.css";

import "prismjs/components/prism-json";
import "prismjs/components/prism-jsonp";
import "prismjs/components/prism-json5";

let headers = [
  {
    text: "Friendly Name",
    value: "friendlyName",
    sortable: true,
    groupable: false,
  },
  {
    text: "Configuration",
    value: "configurationId",
    sortable: false,
    groupable: false,
  },
  {
    text: "Url",
    value: "url",
    sortable: false,
    groupable: false,
  },
  {
    text: "Active",
    value: "active",
    sortable: false,
    groupable: false,
  },
  { text: "Actions", value: "actions", sortable: false, groupable: false },
];

function validateUrl(value) {
  /* eslint-disable */
  var expression = 
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  /* eslint-enable */

  if (value.match(regex)) return true;
  return "Invalid URL";
}
function validateLength(value, length) {
  return (
    value.length <= length || `Length must be less than ${length} characters`
  );
}
function validateRequired(value) {
  return !!value || "Required";
}

export default {
  data() {
    return {
      headers: headers,
      dialog: false,
      newWebhook: {
        friendlyName: "",
        url: "",
        configurationId: "",
      },
      rules: {
        required: (value) => validateRequired(value),
        url: (value) => validateUrl(value),
        length255: (value) => validateLength(value, 255),
        length50: (value) => validateLength(value, 50),
      },
    };
  },
  computed: {
    ...mapState({
      isLoadingAccount: (state) => state.isLoadingAccount,
      webhookError: (state) => state.webhookError,
      webhooks: (state) => state.webhooks,
      configurationList: (state) => state.configurationList,
    }),
    allowSave() {
      return !(
        this.validFriendlyName() &&
        this.validUrl() &&
        this.validConfiguration()
      );
    },
  },
  methods: {
    ...mapActions([
      "loadWebhooks",
      "loadConfigurationList",
      "addWebhook",
      "deleteWebhook",
      "toggleWebhook",
    ]),
    configName(item) {
      return `${item.configurationName} (${item.configurationId})`;
    },
    add() {
      this.addWebhook(this.newWebhook);
      this.dialog = false;
      this.newWebhook = {
        friendlyName: "",
        url: "",
        configurationId: "",
      };
    },
    deleteWH(item) {
      this.deleteWebhook(item.webhookStub);
    },
    toggle(item) {
      this.toggleWebhook(item.webhookStub);
    },
    close() {
      this.newWebhook = {
        friendlyName: "",
        url: "",
      };
      this.dialog = false;
    },
    validFriendlyName() {
      return (
        validateRequired(this.newWebhook.friendlyName) === true &&
        validateLength(this.newWebhook.friendlyName, 50) === true
      );
    },
    validUrl() {
      return (
        validateRequired(this.newWebhook.url) === true &&
        validateLength(this.newWebhook.url, 255) === true &&
        validateUrl(this.newWebhook.url) === true
      );
    },
    validConfiguration() {
      return validateRequired(this.newWebhook.configurationId) === true;
    },
  },
  created() {
    this.loadWebhooks();
    this.loadConfigurationList();
  },
  mounted() {
    Prism.highlightAll();
  },
};
</script>

<style>
</style>